import React from 'react';
import PropTypes from 'prop-types';
import {format} from "date-fns";
import VisibilitySensor from 'react-visibility-sensor'

const BroadcastItem = ({item}) => {
    const starttime = format(new Date(item.startdatetime), 'HH:mm')
    const endtime = format(new Date(item.stopdatetime), 'HH:mm')

    return (
        <VisibilitySensor>
            {({isVisible}) => (
                <div className="card-item">
                    <div className="card-item__time">{starttime} - {endtime}</div>
                    <div className="card-item__content flag-object">
                        <div className="flag-object__aside">
                            <div className="card-item__image image-placeholder image-placeholder--1-1">
                                {isVisible ?
                                    <div className="image"
                                         style={{backgroundImage: 'url(' + item.image_url_200x200 + ')'}}/>
                                    : null}
                            </div>
                        </div>
                        <div className="card-item__content flag-object__body">
                            <div className="card-item__title">{item.title}</div>
                            {item.presenters ?
                                <div className='card-item__presenter'>
                                    {item.presenters}
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            )
            }
        </VisibilitySensor>
    );
};

BroadcastItem.propTypes = {
    item: PropTypes.shape({
        'startdatetime': PropTypes.string.isRequired,
        'stopdatetime': PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
        broadcaster: PropTypes.string
    })
};

export default BroadcastItem;
