import React from 'react';
import Frequency from "./Frequency/Frequency";

const Single = ({items}) => {
    let rows = items.map((el, i) => <Frequency key={i} label={el.text} frequency={el.frequency} />)

    return (
        <div className='frequency__list single'>
            {rows}
        </div>
    )
}

export default Single;
