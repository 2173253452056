import React from "react";
import Loader from "../../components/UI/Loader/Loader";
import Broadcast from "./NowOnAir/Broadcast/Broadcast";

const Header = ({ broadcast, showBroadcaster }) => {
    const showLoader = !broadcast || !broadcast.title;

    if (showLoader) {
        return <Loader />;
    } else {
        return (
            <div className="header">
                <Broadcast
                    broadcast={broadcast}
                    showBroadcaster={showBroadcaster}
                />
            </div>
        );
    }
};

export default Header;
