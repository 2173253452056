import React, {Component} from 'react';

class Appje extends Component {
    componentDidMount() {
        // We have to init here because we otherwise don't have the correct div
        window.NpoStudiomessenger.init(this.props.config)
        window.NpoStudiomessenger.show()

        window.document.addEventListener('npo-studio-messenger-closed', this.props.closed)
    }

    componentWillUnmount() {
        window.document.removeEventListener('npo-studio-messenger-closed', this.props.closed)
    }

    render() {
        return (
            <>
                <div className='appje-container'>
                    <div id="js_nposm" className={'npo-studio-messenger' } />
                </div>
            </>
        )
    }
}

export default Appje;
