import React from 'react';
import PlayedTrack from "./PlayedTrack/PlayedTrack";
import Loader from "../../components/UI/Loader/Loader";

const PlayedTracks = ({tracks}) => {
    let items = <Loader/>;

    if (tracks && tracks.length) {
        items = tracks.map((el, i) => <PlayedTrack key={i} track={el}/>)
    }

    return (
        <div className='playlist'>
            {items}
        </div>
    );
}

export default PlayedTracks;