import React, { Component } from 'react'
import './App.scss'

import {BrowserRouter} from 'react-router-dom'
import Layout from "./containers/Layout/Layout";

import 'babel-polyfill';

class App extends Component {
    state = {
        config: window.config
    }

  render() {
    return (
    	<>
			<BrowserRouter basename={this.state.config.location}>
				<Layout config={this.state.config} />
        	</BrowserRouter>
		</>
    );
  }
}

export default App;
