import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";

const initSentry = () => {
    Sentry.init({
        denyUrls: [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            /^chrome-extension:\/\//i,
        ],
        dsn: process.env.REACT_APP_SENTRY_DSN,
        environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
        integrations: [new HttpClient()],

        // This option is required for capturing headers and cookies.
        sendDefaultPii: true,
    });
}

export default initSentry;
