import React from 'react';
import Loader from "../UI/Loader/Loader"
import BroadcastItem from "./BroadcastDay/BroadcastItem/BroadcastItem";

const BroadcastItems = ({broadcasts}) => {

    let items = <Loader/>;

    if (broadcasts && (broadcasts.length || broadcasts !== null)) {
        items = broadcasts.map((el, i) => <BroadcastItem key={i} item={el}/>)
    }

    return (
        <div className='BroadcastItems'>
            {items}
        </div>
    );
}

export default BroadcastItems;
