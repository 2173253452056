import React from 'react'

const backdrop = (props) => {
    let state = ""

    if (props.visible) {
        state = " is-visible"
    }

    return (
        props.show ?
            <div className={"backdrop" + state} onClick={props.clicked}/> : null
    )
}

backdrop.defaultProps = {
    visible: true
}

export default backdrop