import React, {Component} from 'react';
import Frequency from "./Frequency/Frequency";

class Double extends Component {
    state = {
        cableVisible: false
    }

    FrequencyToggleHandler = (state) => {
        this.setState((prevState) => {
            return {
                cableVisible: state
            }
        })
    }

    render() {
        const cable = this.props.cable.map((el, i) => <Frequency key={i} label={el.text} frequency={el.frequency} />)
        const ether = this.props.ether.map((el, i) => <Frequency key={i} label={el.text} frequency={el.frequency} />)

        return (
            <div className='frequency__list double'>
                <div className='frequency__buttons'>
                    <button className={this.state.cableVisible ? '' : 'active'} onClick={() => this.FrequencyToggleHandler(false)}>Ether</button>
                    <button className={this.state.cableVisible ? 'active' : ''}  onClick={() => this.FrequencyToggleHandler(true)}>Kabel</button>
                </div>
                <div className={this.state.cableVisible ? '' : 'is-hidden'}>
                    {cable}
                </div>
                <div className={this.state.cableVisible ? 'is-hidden' : ''}>
                    {ether}
                </div>
            </div>
        );
    }
}

export default Double;
