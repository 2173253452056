import React from 'react';

const Frequency = ({label, frequency, link}) => {
    return (
        <div className='frequency__row'>
                <div className='label'>{label}</div>
                <div className='frequency'>
                    {link ?
                        <a href={link}>SPEEL AF</a>
                        :
                        <>
                            {frequency}
                        </>
                    }
                </div>
            </div>
    )
}

export default Frequency;
