import React from 'react';
import PropTypes from 'prop-types';

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';

import Backdrop from "../../UI/Backdrop/Backdrop";

const VolumeSlider = props => {
    let state = " is-closed"

    if (props.show) {
        state = " is-open"
    }

    /**
     * We mount/unmount the slider on props.show due to the defaultValue not working properly otherwise
     */
    return (
        <>
            <Backdrop show={props.show} visible={false} clicked={props.closed}/>

            <div className={"volume-slider" + state}>
                {props.show ?
                    <Slider
                        vertical
                        onChange={props.volumeChanged}
                        defaultValue={props.default}
                    />
                    : null}
            </div>
        </>
    );
};

VolumeSlider.propTypes = {
    show: PropTypes.bool.isRequired,
    closed: PropTypes.func.isRequired,
    default: PropTypes.number,
    volumeChanged: PropTypes.func
};

export default VolumeSlider;