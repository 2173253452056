import React from 'react';
import Double from './Frequencies/Double'
import Single from './Frequencies/Single'
import SubChannels from './Frequencies/SubChannels'

const Frequencies = (props) => {
    // We get correct url's for the stores from the meta data
    const metas = [...document.getElementsByTagName('meta')],
        android = metas.filter(el => el.getAttribute('name') === 'google-play-app')[0] || {},
        ios = metas.filter(el => el.getAttribute('name') === 'apple-itunes-app')[0] || {};

    let table;

    if (props.config.frequencies.ether && props.config.frequencies.cable) {
        table = <Double cable={props.config.frequencies.cable} ether={props.config.frequencies.ether}/>
    } else if (props.config.frequencies.ether) {
        table = <Single items={props.config.frequencies.ether} />
    } else if (props.config.frequencies.subChannels) {
        table = <SubChannels items={props.config.frequencies.subChannels} />
    }

    return (
        <div className="frequencies-wrapper">
            {ios.content || android.content ?
                <div className="frequencies__download">
                    <div className="frequencies__download__title">
                        {props.config.DownloadTitle}
                    </div>
                    <div className="frequencies__download__body">
                        <ul>
                            {ios.content && <li><a href={ios.content} target='_blank' rel='noopener noreferrer'>Download voor iOS</a></li>}
                            {android.content && <li><a href={android.content} target='_blank' rel='noopener noreferrer'>Download voor Android</a></li>}
                        </ul>
                    </div>
                </div>
                : null}
            <div className="frequencies__dab">
                <div className="frequencies__dab__title">
                    {props.config.dabTitle}
                </div>
                <div className="frequencies__body"
                     dangerouslySetInnerHTML={{__html: props.config.dabBody}}/>
            </div>
            <div className="frequencies__title">
                {props.config.title}
            </div>
            <div className="frequencies__body"
                 dangerouslySetInnerHTML={{__html: props.config.body}}/>
            <div className="frequencies__table">
                {table}
            </div>
            {props.config.showMap && (
                <div className="dab__map_container">
                    {props.config.mapTitle && (
                        <div className="dab__map_title">
                            {props.config.mapTitle}
                        </div>
                    )}
                    <div className="dab__map" />
                </div>
            )}
        </div>
    )
};

export default Frequencies;
