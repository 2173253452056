import React from 'react';
import VisibilitySensor from 'react-visibility-sensor'

const NewsItem = ({item}) => {
    let image = item.images.image_intro || item.images.image_fulltext
    let extraClass = '';

    switch (item.category_title) {
        case 'Onderzoek':
        case 'Achtergrond':
            extraClass = ' color--background';
            break;
        case 'Podcast':
            extraClass = ' color--podcast';
            break;
        case 'Live':
            extraClass = ' color--live';
            break;
        case 'Sport':
            extraClass = ' color--sport';
            break;
        default:
    }

    return (
        <VisibilitySensor>
            {({isVisible}) => (
                <a className="nieuws-item flag-object" href={item.readmore_link} rel='noopener noreferrer' target='_blank'>
                    <div className="flag-object__aside">
                        <div className="nieuws-item__image image-placeholder image-placeholder--4-3">
                            {isVisible ?
                                <div className="image" style={{backgroundImage: 'url(' + image + ')'}}/>
                                : null}
                        </div>
                    </div>
                    <div className="nieuws-item__content flag-object__body">
                        <div className={"nieuws-category__title" + extraClass}>{item.category_title}</div>
                        <div className="nieuws-item__title">{item.title}</div>
                        {item.channel ?
                            <div className="nieuws-item__channel">({item.channel}</div>
                            : null}
                    </div>
                </a>
                )
            }
        </VisibilitySensor>
    );
};

export default NewsItem;
