import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from "../../UI/Backdrop/Backdrop";

const SubChannelSelector = props => {
    const channels = props.channels.map((el, i) =>
        <li key={i}>
            <a href={el.link} className='subchannels__link'>{el.text}</a>
        </li>
    )

    let state = " is-closed"

    if (props.show) {
        state = " is-open"
    }

    return (
        <>
            <Backdrop show={props.show} visible={false} clicked={props.closed}/>

            <div className={"subchannel-selector" + state}>
                <ul>
                    {channels}
                </ul>
            </div>
        </>
    );
};

SubChannelSelector.propTypes = {
    channels: PropTypes.array.isRequired,
	subchannelClicked: PropTypes.func
};

export default SubChannelSelector;
