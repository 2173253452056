import React from 'react';
import NewsItem from "./NewsItem/NewsItem";
import Loader from "../../components/UI/Loader/Loader";

const NewsItems = ({newsItems}) => {

	let items = <Loader/>;

	if (newsItems && newsItems.length) {
		items = newsItems.map(el => <NewsItem key={el.id} item={el} />)
	}

	return (
		<div className='nieuws'>
			{items}
		</div>
	);
}

export default NewsItems;
