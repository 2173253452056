import React from 'react';
import {NavLink} from "react-router-dom";

const NavigationItem = (props) => {
    return (
        <li className="menu__item">
            <NavLink exact to={props.link} activeClassName='is-active' className='menu__link'>{props.text}</NavLink>
        </li>
    );
};

export default NavigationItem;