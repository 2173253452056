import React from 'react';

const Loader = () => {
    return (
        <div>
            <div className="loader">
                <div className="loader__bar1"></div>
                <div className="loader__bar2"></div>
                <div className="loader__bar3"></div>
                <div className="loader__bar4"></div>
                <div className="loader__bar5"></div>
            </div>
        </div>
    );
};

export default Loader;