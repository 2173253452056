import React from 'react';
import PropTypes from 'prop-types';
import {format, parse} from "date-fns";

const PlayedTrack = ({track}) => {
	// Safari fix
	const time = format(parse(track.startdatetime), 'HH:mm')
	const image = track.image_url_200x200 && track.image_url_200x200 !== 'null' ?
		track.image_url_200x200 : track.fallbackImage;

	return (
		<div className="card-item">
			<div className="card-item__time card-item__track">
				{time}
			</div>
			<div className="card-item__content flag-object">
				{track.composer ?
					<>
					<div className="flag-object__aside">
						<div className="card-item__image image-placeholder--1-1"><img src={image} alt="" /></div>
					</div>
					<div className="flag-object__body">
						<div className="card-item__composer">{track.composer_name || track.composer}</div>
						<div className="card-item__title">{track.title}</div>
						<div className="card-item__orchestra">{track.orchestra}</div>
						<div className="card-item__director">{track.director}</div>
						<div className="card-item__subtitle">{track.soloistsEnsemble || track.artist}</div>
						<div className="card-item__label">{track.label}</div>
						<div className="card-item__labelnr">{track.labelcatalognr}</div>
					</div>
					</>
					:
					<>
					<div className="flag-object__aside">
						<div className="card-item__image image-placeholder--1-1"><img src={image} alt="" /></div>
					</div>
					<div className="flag-object__body">
						<div className="card-item__title">{track.title}</div>
						<div className="card-item__subtitle">{track.artist}</div>
					</div>
					</>
				}
			</div>
		</div>
	)
}

PlayedTrack.propTypes = {
	track: PropTypes.shape({
		artist: PropTypes.string.isRequired,
		soloistsEnsemble: PropTypes.string,
		title: PropTypes.string.isRequired,
		director: PropTypes.string,
		composer: PropTypes.string,
		composer_name: PropTypes.string,
		orchestra: PropTypes.string,
		label: PropTypes.string,
		labelcatalognr: PropTypes.string,
		image_url_200x200: PropTypes.string,
		fallbackImage: PropTypes.string,
	})

}

export default PlayedTrack
