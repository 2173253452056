function trackPage() {
  // we need to manually create a new pageTracker and track the pageView
  const tracker = window?.npotag?.newPageTracker(window.tag, window.pageTrackers);
  tracker?.pageView();
}

const TopSpin = {
    initialize: () => {
        const NpoTag = (config) => {
            (function (document, tag) {
              const scriptTag = document.createElement(tag),
                firstScriptTag = document.getElementsByTagName(tag)[0];

              scriptTag.src = "https://npotag.npo-data.nl/prod/npo-tag.min.js";
              scriptTag.async = true;
              firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

              scriptTag.addEventListener("load", function () {
                const tag = window.npotag.newTag(
                    {
                        brand: config.brand,
                        brand_id: config.brand_id,
                        platform: "site",
                        platform_version: "1.0.0",
                        environment: config.environment,
                        chapter_1: 'radioplayer',
                        chapter_2: config.chapter_2,
                    },
                    [window.npotag.newATInternetPlugin(), window.npotag.newGovoltePlugin()],
                );

                window.tag = tag;
                window.NpoTagContext = tag.getContext()

                const tracker = window.npotag.newPageTracker(
                  tag,
                  window.pageTrackers
                );

                tracker.pageView();
                window.tracker = tracker;
              });
            })(document, "script");
      };
      trackPage();

      return {
        NpoTag,
      };
    },
    doCount: (page, config) => {
        window.pageTrackers = {
          page: page,
        };

        if (typeof window.npotag === "undefined") {
          TopSpin.initialize().NpoTag(config);
        } else {
            trackPage();
        }
      },

      doClick: (trackObject) => {
        if (window.tracker) {
          window.tracker.click(trackObject);
        }
      },
  };

  export default TopSpin;
