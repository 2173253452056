import React from 'react';
import PropTypes from 'prop-types'

const SterBanner = (props) => {
    return (
            <div className="advertisement-wrapper">
                <div className="advertisement-title">STER advertentie</div>
                <div className="advertisement">
                    <div id='sterad'>
                        <div id={props.sterdiv} />
                    </div>
                </div>
            </div>
        )
};

SterBanner.propTypes = {
    sterdiv: PropTypes.string.isRequired
}

export default SterBanner;
