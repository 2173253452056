import React from 'react';

import NavigationItem from '../../components/NavigationItem/NavigationItem'

const NavigationItems = (props) => {
    const items = props.items.map(el =>
        <NavigationItem
            key={el.id}
            active={el.id === props.active}
            id={el.id}
            link={el.link}
            text={el.text}/>
    )

    return (
        <ul className="menu">
            {items}
        </ul>
    );
};

export default NavigationItems;